<template>
  <div>
  <el-dialog
      title="Kontakt / Supportanfrage"
      v-model="showForm"
      width="50%"
      :close-on-click-modal="false"
      :before-close="closeDialog">
      <el-row class="mt-3">
        <el-col :span="11">
          <h6>Ihr Name*</h6>
          <el-input v-model="form.name"></el-input>
        </el-col>
        <el-col :span="11" :offset="1">
          <h6>Ihre E-Mail Adresse</h6>
          <el-input v-model="form.email"></el-input>
        </el-col>
      </el-row>
    <el-row class="mt-3">
      <el-col :span="23">
        <h6>Betreff*</h6>
        <el-input v-model="form.subject"></el-input>
      </el-col>
    </el-row>
      <el-row class="mt-3">
        <el-col :span="23">
          <h6>Ihre Anfrage*</h6>
          <el-input type="textarea" v-model="form.content" rows="11"></el-input>
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col :span="24">
          <h6>Datei-Anhänge (optional)</h6>
          <el-upload
            v-model:file-list="fileList"
            :action="uploadURL"
            :headers="{ Authorization: 'Bearer ' + uploadURLToken}"
            multiple
            drag
            :on-success="onSuccessFileUpload"
            :on-remove="onRemoveFileUpload"
          >
            <el-icon class="el-icon-upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              Dateien hier her ziehen oder <em>klicken für Auswahl</em>
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col :span="24" class="dialog-footer text-right">
            <el-button @click="closeDialog">Abbrechen</el-button>
            <el-button type="primary" @click="sendForm()">Senden</el-button>
        </el-col>
      </el-row>

  </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import DacyServer from "../../dacyserver";
import _ from "lodash";

export default {
  name: "ContactForm",
  props: ['modelValue', 'show'],
  components: {},
  data() {
    return {
      form: {
        name: null,
        email: null,
        content: null,
        subject: "DACY ",
        files: []
      },
      fileList: [],
      showForm: false
    }
  },
  mounted: async function () {
    this.showForm = this.modelValue
  },
  computed: {
    uploadURL: function() {
      return DacyServer.getInstance().getURL() + "/upload"
    },
    uploadURLToken: function() {
      return this.$auth.token()
    }
},
  watch: {
    modelValue: function (val) {
      this.showForm = val
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:modelValue', false)
    },
    onSuccessFileUpload(response) {
      this.form.files.push(response.filename)
    },
    onRemoveFileUpload(file) {
      _.remove(this.form.files, function(f) {
        return f == file.response.filename
      })
    },
    async sendForm() {

      if(!this.form.name || !this.form.content) {
        this.$message({
          message: "Bitte alle benötigten Felder (mit Stern markiert) ausfüllen",
          type: 'error'
        });
      }
      else {

        try {
          await axios.post('/messaging/contact', this.form)

          this.$message({
            message: "Ihre Nachricht wurde gesendet!",
            type: 'success'
          });
          this.showForm = false
          this.form.name = null
          this.form.content = null
          this.form.files = []
          this.fileList = []
          this.closeDialog()

        }
        catch(e) {
          this.$message({
            message: "Ihre Nachricht konnte nicht gesendet werden!",
            type: 'error'
          });
        }


      }

    }
}

}
</script>
<style scoped>
</style>
