<template>
  <el-container>
    <contact-form v-model="showContactForm"></contact-form>
    <setting-therapy-protocol-own-list-form-modal v-model="showEditForm" :protocolown="currentRow" @after-save="onAfterSaveProtocolOwn"></setting-therapy-protocol-own-list-form-modal>

    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="11" :sm="9" :span="7" :xl="4" :lg="6">
          <el-button-group>

            <el-tooltip
                content="Kontakt / Supportanfrage"
                placement="bottom"
            >
              <el-button @click="showContactForm = true" icon="el-icon-chat-line-square" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Einstellungen"
              placement="bottom"
            >
              <el-button @click="openWindow('setting')" icon="el-icon-setting" plain></el-button>
            </el-tooltip>

          </el-button-group>
        </el-col>
        <el-col :xs="9" :sm="11" :span="5" :xl="16" :lg="14">

        </el-col>
        <el-col :span="2" style="line-height:50px; text-align:right">
          <el-button @click="navigateToStart" class="mr-3 pl-3 pr-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
        </el-col>
        <el-col :sm="2" :span="2" class="text-right align-content-end">
          <main-menu></main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main>

      <el-card class="box-card">
        <h3>Knowledgebase</h3>
        <el-tabs>
          <el-tab-pane label="Therapien">
            <patient-therapy click-action="therapy-protocol-own-list" @on-therapy-protocol-select="onTherapyProtocolSelect"></patient-therapy>
          </el-tab-pane>
          <el-tab-pane label="Diagnosen">
            <el-row>
              <el-col :span="5">

                <el-input v-model="diagnosisSearch"></el-input>

                <el-table
                  :data="filteredDiagnosis"
                  style="width: 100%"
                  :show-header="false"
                  highlight-current-row
                  @row-click="onDiagnosisRowClick"
                  height="500"
                  stripe>
                  <el-table-column>
                    <template #default="scope">
                      {{scope.row.code}}, {{scope.row.title}}
                    </template>
                  </el-table-column>
                </el-table>

              </el-col>
              <el-col :span="17" :offset="1">

                <h5 v-if="currentDiagnosis.id"><el-tag>{{currentDiagnosis.code}}</el-tag> {{currentDiagnosis.title}}</h5>

                <el-row class="mt-4" v-if="currentDiagnosis.id">

                  <el-col :span="2">

                    <div v-if="currentDiagnosis.icdo3t.length > 0">
                      <h6>ICD-O3-T</h6>
                      <el-menu mode="vertical" size="small" :collapse="true" style="width:85px !important;">
                        <el-menu-item v-for="icd in currentDiagnosis.icdo3t" @click="currentTNM = icd" :index="icd.icdo3" :key="icd.icdo3">{{icd.icdo3}} <i v-if="icd.default" class="icon el-icon-star-on text-danger pb-1"></i>
                          <template #title>{{icd.icdo3_description}}</template>
                        </el-menu-item>
                      </el-menu>
                    </div>

                    <div v-if="currentDiagnosis.icdo3h.length > 0">
                      <h6>ICD-O3-H</h6>
                      <el-menu mode="vertical" size="small" :collapse="true" style="width:85px !important;">
                        <el-menu-item v-for="icd in currentDiagnosis.icdo3h" @click="currentTNM = icd" :index="icd.icdo3" :key="icd.icdo3">{{icd.icdo3}} <i v-if="icd.default" class="icon el-icon-star-on text-danger pb-1"></i>
                          <template #title>{{icd.icdo3_description}}</template>
                        </el-menu-item>
                      </el-menu>
                    </div>

                  </el-col>

                  <el-col :span="21" :offset="1">

                    <div v-if="currentTNM">

                      <el-row>
                        <el-col :span="15">
                          <h5 class="mb-3">TNM für {{currentTNM.icdo3}} {{currentTNM.icdo3_description}}</h5>
                        </el-col>
                        <el-col :span="8" :offset="1" class="text-right">
                          <el-button v-if="editMode" size="small" @click="saveCurrentTNM"><i class="el-icon el-icon-document"></i> Speichern</el-button>
                          <el-button :type="(editMode ? 'primary' : 'secondary')" size="small" @click="editMode = !editMode"><i class="el-icon el-icon-edit"></i></el-button>
                        </el-col>
                      </el-row>

                      <div v-if="editMode">

                        <table class="w-100">
                          <tr v-for="tnm in currentTNM.tnm" :key="tnm.value">
                            <td style="width:60px;" class="p-1 align-text-top">
                              <el-input v-model="tnm.prefix"></el-input>
                            </td>
                            <td style="width:60px;" class="p-1 align-text-top">
                              <el-input v-model="tnm.type"></el-input>
                            </td>
                            <td style="width:80px;" class="p-1 align-text-top">
                              <el-input v-model="tnm.value"></el-input>
                            </td>
                            <td class="p-1">
                              <el-input type="textarea" rows="2" class="w-100" v-model="tnm.description"></el-input>
                            </td>
                          </tr>
                        </table>

                      </div>
                      <div v-else>

                        <TNMDisplayPart :data="currentTNM.tnm" part="cT"></TNMDisplayPart>
                        <TNMDisplayPart :data="currentTNM.tnm" part="pT"></TNMDisplayPart>
                        <TNMDisplayPart :data="currentTNM.tnm" part="T"></TNMDisplayPart>
                        <TNMDisplayPart :data="currentTNM.tnm" part="cN"></TNMDisplayPart>
                        <TNMDisplayPart :data="currentTNM.tnm" part="pN"></TNMDisplayPart>
                        <TNMDisplayPart :data="currentTNM.tnm" part="N"></TNMDisplayPart>
                        <TNMDisplayPart :data="currentTNM.tnm" part="M"></TNMDisplayPart>

                      </div>

                    </div>

                  </el-col>

                </el-row>

              </el-col>
            </el-row>

          </el-tab-pane>
        </el-tabs>

      </el-card>

    </el-main>
  </el-container>
</template>

<script>
import ContactForm from "@/components/parts/ContactForm";
import MainMenu from "@/components/parts/MainMenu";
import PatientTherapy from "../components/patient/PatientTherapy";
import SettingTherapyProtocolOwnListFormModal from "../components/setting/SettingTherapyProtocolOwnListFormModal";
import {mapActions, mapGetters} from "vuex";
import TNMDisplayPart from "../components/parts/TNMDisplayPart.vue";

export default {
  name: "Knowledgebase",
  props: [],
  components: {
    TNMDisplayPart,
    PatientTherapy,
    MainMenu,
    ContactForm,
    SettingTherapyProtocolOwnListFormModal
  },
  data () {
    return {
      showContactForm: false,
      showEditForm: false,
      currentRow: null,
      currentDiagnosis: {},
      currentTNM: null,
      diagnosisSearch: '',
      editMode: false
    }
  },
  mounted: async function () {
    await this.getOncologyDiagnosis()
  },
  computed: {
    ...mapGetters('diagnosis', ['oncologyDiagnosis']),
    filteredDiagnosis() {

      if(this.diagnosisSearch !== '') {
        const search = this.diagnosisSearch.toLowerCase()
        let filtered = []
        for(const diag of this.oncologyDiagnosis) {
          if(diag.code.toLowerCase().indexOf(search) !== -1 || diag.title.toLowerCase().indexOf(search) !== -1) {
            filtered.push(diag)
          }
        }
        return filtered
      }

      return this.oncologyDiagnosis
    }
  },
  watch: {
  },
  methods: {
    ...mapActions('diagnosis', ['getOncologyDiagnosis']),
    ...mapActions('diagnosis', ['getDiagnosisById']),
    ...mapActions('diagnosis', ['saveDiagnosisTNM']),
    openWindow(path) {
      if(path === 'setting') {
        this.$root.openNewWindow('/app/setting')
      }
    },
    navigateToStart() {
      this.$router.push({ name: 'dashboard' } )
    },
    onTherapyProtocolSelect(protocol) {
      if(!protocol) return
      this.currentRow = {
        id: null,
        title: protocol.title,
        kz_title: protocol.kz_title,
        protocol_id: protocol.id,
        meta: {
          protocol: protocol
        }
      }
      this.showEditForm = true
    },
    onAfterSaveProtocolOwn() {
      this.showEditForm = false
    },
    async onDiagnosisRowClick(row) {
      this.currentTNM = null
      this.currentDiagnosis = await this.getDiagnosisById(row.id)
    },
    async saveCurrentTNM() {
      if(!this.currentTNM) {
        return
      }

      const result = await this.saveDiagnosisTNM({ diagnosis_id: this.currentDiagnosis.id, tnm: this.currentTNM })
      if(result) {
        this.$notify({
          message: 'TNM Klassifikation wurde gespeichert',
          type: 'success',
          position: 'bottom-left'
        });
      }
      else {
        this.$notify({
          message: 'TNM Klassifikation wurde nicht gespeichert',
          type: 'error',
          position: 'bottom-left'
        });
      }

    }
}

}
</script>
<style>
#patient-therapy-headline {
  display:none !important;
}
</style>
